import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import {MobileStepper} from "@mui/material";
import Paper from "@mui/material/Paper";
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "Western Digital",
    imgPath: "https://1000logos.net/wp-content/uploads/2022/07/Western-Digital-logo.png",
  },
  // {
  //   label: "FIDELITY INVESTMENTS",
  //   imgPath: "https://1000logos.net/wp-content/uploads/2017/11/Fidelity-Logo.png",
  // },
  {
    label: "VISA",
    imgPath: "https://1000logos.net/wp-content/uploads/2021/11/VISA-logo.png",
  },
  // {
  //   label: "NETAPP",
  //   imgPath:
  //     "https://upload.wikimedia.org/wikipedia/commons/9/9f/NetApp_logo.svg",
  // },  
  {
    label: "AMD",
    imgPath:
      "https://1000logos.net/wp-content/uploads/2020/05/AMD-Logo.png",
  },
  {
    label: "CISCO",
    imgPath: "https://1000logos.net/wp-content/uploads/2016/11/Cisco-logo.png",
  },
  {
    label: "AMAZON",
    imgPath:
      "https://images-na.ssl-images-amazon.com/images/G/01/gc/designs/livepreview/amazon_dkblue_noto_email_v2016_us-main._CB468775337_.png",
  },
  {
    label: "INTUIT",
    imgPath: "https://www.logo.wine/a/logo/Intuit/Intuit-Logo.wine.svg",
  },
];

function CarouselPage() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1, margin: "0 auto" }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "background.default",
        }}
      >
        <Typography
          sx={{ margin: "0 auto", fontSize: "1.6rem", fontWeight: "bold" }}
        >
          {images[activeStep].label}
        </Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: 255,
                  display: "block",
                  maxWidth: 400,
                  overflow: "hidden",
                  width: "100%",
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
}

export default CarouselPage;
