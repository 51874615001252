import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {apiLink} from '../../../mainurl'



function BranchwiseCount() {
  const [branchwiseCount,setBranchwiseCount]=useState("");

  useEffect(() => {
    fetch(
      apiLink+"stats/placed_branchwise_graph" ,
      {
        method: "GET",
        headers: {
          'accept': "application/json",
        },
      }
    ).then((response) => response.json())
    .then((data) => {
        setBranchwiseCount(data);
    });
  }, [])
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Branchwise Count placed',
      },
    },
  };

  const labels = branchwiseCount[2];

  const data = {
    labels,
    datasets: [
      {
        label: 'Count Placed',
        data:  branchwiseCount[3],
        backgroundColor: '#FF87B2',
      }
    ],
  };

  return <Bar options={options} data={data} />;
}
export default BranchwiseCount;
