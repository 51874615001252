import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {apiLink} from '../../../mainurl'


function OverallPackage() {

  const [overallPack,setOverallPack]=useState("");

  useEffect(() => {
    fetch(
      apiLink+"stats/average_package" ,
      {
        method: "GET",
        headers: {
          'accept': "application/json",
        },
      }
    ).then((response) => response.json())
    .then((data) => {
        setOverallPack(data);
    });
  }, [])

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
   const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Overall Package Analysis',
      },
    },
  };
  
  const labels = ['Average','Median','Highest','Lowest'];
  
   const data = {
    labels,
    datasets: [
      {
        label: 'CTC(in LPA)',
        data: overallPack,
        backgroundColor: '#224B0C',
      }
    ],
  };


  return <Bar options={options} data={data} />;
}
export default OverallPackage;
