import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {apiLink} from '../../../mainurl'


function MedianPackage() {
  const [medianPack,setMedianPack]=useState("");
  useEffect(() => {
    fetch(
      apiLink+"stats/branchwise_average_package" ,
      {
        method: "GET",
        headers: {
          'accept': "application/json",
        },
      }
    ).then((response) => response.json())
    .then((data) => {
      setMedianPack(data);
    });
  }, [])
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
   const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Median Package-Branchwise',
      },
    },
  };
  
  const labels = medianPack.branch;
  
   const data = {
    labels,
    datasets: [
      {
        label: 'Median Package',
        data: medianPack.median,
        backgroundColor: '#FFB200',
      }
    ],
  };

  
  return <Bar options={options} data={data} />;
}
export default MedianPackage;
