import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {apiLink} from '../../../mainurl'


function Tierwise() {

  const [tierWise,settierWise]=useState("");

  useEffect(() => {
    fetch(
      apiLink+"stats/tierwise_placed_count" ,
      {
        method: "GET",
        headers: {
          'accept': "application/json",
        },
      }
    ).then((response) => response.json())
    .then((data) => {
        settierWise(data);
    });
  }, [])

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
   const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'TierWise',
      },
    },
  };
  
  const labels = tierWise[0];
  
   const data = {
    labels,
    datasets: [
      {
        label: 'No of placed',
        data: tierWise[1],
        backgroundColor: '#607EAA',
      }
    ],
  };


  return <Bar options={options} data={data} />;
}
export default Tierwise;
