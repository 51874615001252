import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { Pie } from 'react-chartjs-2';
import { useState } from 'react';
import { useEffect } from 'react';
import {apiLink} from '../../../mainurl'
import Box from '@mui/material/Box';



function GenderWise() {
  const [genderWise,setGenderWise]=useState("");
  ChartJS.register(ArcElement, Tooltip, Legend);

   const data = {
    labels: ['Male %','Female %'],
    datasets: [
      {
        label: 'Genderwise Placement',
        data: genderWise,
        backgroundColor: [
            'rgba(54, 162, 235, 1)',
            'rgba(255, 99, 132, 1)'
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)'
        ],
        borderWidth: 1,
      },
    ],
  };
  
  useEffect(() => {
    fetch(
      apiLink+"stats/genderwise_placed_ratio" ,
      {
        method: "GET",
        headers: {
          'accept': "application/json",
        },
      }
    ).then((response) => response.json())
    .then((data) => {
        setGenderWise(data);
    });
  }, [])

  return (<Box sx={{padding:'13rem'}}>
    <Pie data={data} />
  </Box>)
  ;
}
export default GenderWise;
